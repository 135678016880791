<template>
  <v-container class="pa-0">
    <div
      class="d-flex flex-column align-center justify-space-between receipt-lottery-page"
    >
      <div>
        <h4 class="text-uppercase px-2">{{ $t("receiptLottery.title") }}</h4>
        <div
          v-if="code"
          class="pa-2 d-flex flex-column justify-space-between"
          :key="key"
        >
          <div class="d-flex justify-center align-center flex-column">
            <div class="barcode-wraper">
              <barcode
                :value="code"
                format="CODE128"
                :displayValue="false"
                class="align-self-center"
                flat
              />
              <div class="mt-n2 text-body-0 text-center">
                {{ $t("receiptLottery.number") }}
              </div>
              <div class="text-h3 font-weight-bold mb-2 text-center">
                {{ code }}
              </div>
            </div>
            <div class="my-3 mx-8 text-center pre-wrap">
              {{ $t("receiptLottery.info") }}
            </div>
          </div>
        </div>
        <div v-else class="px-2">
          <div class="text-body-0">
            {{ $t("receiptLottery.description") }}
          </div>
          <v-text-field
            :label="`${$t('receiptLottery.code')}`"
            class="mt-6"
            outlined
            rounded
            dense
            height="50"
            v-model="insertedCode"
            append-icon="$barcodeScan"
            @click:append="scanCard"
          />
          <v-btn
            color="primary"
            depressed
            rounded
            x-large
            block
            class="mb-6"
            @click="addCard"
            :loading="loading"
            :disabled="!insertedCode"
            >{{ $t("receiptLottery.addCard") }}</v-btn
          >
        </div>
      </div>
      <div class="bottom-part" v-if="code">
        <div class="lottery-card">
          <v-img src="/img_layout/lottery/logo.png" contain></v-img>
        </div>
        <v-btn @click="removeCard" class="my-2" text depressed rounded small>{{
          $t("receiptLottery.removeCard")
        }}</v-btn>
      </div>
    </div>
  </v-container>
</template>
<style lang="scss">
.receipt-lottery-page {
  min-height: calc(100vh - 142px);
  .v-text-field--outlined.v-input--dense .v-label {
    top: 14px !important;
  }
  .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
    .v-input__append-inner {
    margin-top: 12px !important;
  }
  .v-label--active {
    transform: translateY(-20px) scale(0.75) !important;
  }
  .bottom-part {
    background-color: var(--v-grey-lighten1);
    width: 100%;
    border-radius: 25px 25px 0px 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .lottery-card {
      width: 80%;
      max-width: 300px;
      aspect-ratio: 2/1.2;
      background-color: #225688;
      border-radius: 10px;
      margin-top: 25px;
      padding: 15px;
      display: flex;
    }
    .v-btn__content {
      font-size: 14px;
      text-decoration: underline;
    }
  }
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ReceiptLottery",
  data() {
    return {
      code: null,
      loading: false,
      key: 1,
      insertedCode: null
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser",
      getLotteryCodeByUser: "custom/getLotteryCodeByUser"
    }),
    lotteryCode() {
      if (this.user && this.user.userId) {
        return this.getLotteryCodeByUser(this.user.userId);
      } else {
        return this.getLotteryCodeByUser("noAccount");
      }
    }
  },
  methods: {
    ...mapActions({
      addLotteryCode: "custom/addLotteryCode",
      removeLotteryCode: "custom/removeLotteryCode"
    }),
    scanCard() {
      if (window.cordova && window.cordova.plugins.barcodeScanner) {
        try {
          this.loading = true;
          // eslint-disable-next-line no-undef
          cordova.plugins.barcodeScanner.scan(
            result => {
              if (result) {
                this.loading = false;
                //this.addCard(result.text);
                this.insertedCode = result.text;
              }
            },
            error => {
              this.loading = false;
              console.error("Scanning failed", error);
              if (
                error
                  .toString()
                  .toLowerCase()
                  .includes("illegal access")
              ) {
                this.$dialog.confirm({
                  text: `<p class='text-center font-weight-semibold pre-wrap py-3'>${this.$t(
                    "message.cameraPermission"
                  )}</p>`,
                  class: "",
                  actions: {}
                });
              }
            },
            {
              showFlipCameraButton: true,
              showTorchButton: true,
              resultDisplayDuration: 500,
              formats:
                this.formats || "EAN_8,EAN_13,CODE_128,CODE_39,CODE_93,CODABAR"
            }
          );
        } catch (err) {
          console.log(err);
        }
      }
    },
    getCode() {
      if (this.user && this.user.userId) {
        this.code = this.getLotteryCodeByUser(this.user.userId);
      } else {
        this.code = this.getLotteryCodeByUser("noAccount");
      }
    },
    addCard() {
      this.addLotteryCode(this.insertedCode);
      this.insertedCode = null;
      this.getCode();
      this.key++;
    },
    async removeCard() {
      let title = this.$t("receiptLottery.removeCard") + " ";
      let res = await this.$dialog.confirm({
        text: `<h2 class='primary--text text-center'>${title}</h2><p class='text-center font-weight-bold'>Il tuo codice lotteria verrà eliminato, sei sicuro?</p>`,
        class: "confirm-coupon-activation",
        actions: {
          false: {
            color: "var(--v-primary-base)",
            rounded: true,
            text: this.$t("common.cancel")
          },
          true: {
            rounded: true,
            text: this.$t("common.confirm")
          }
        }
      });
      if (res) {
        this.removeLotteryCode();
        this.getCode();
      }
    }
  },
  mounted() {
    this.getCode();
  }
};
</script>
